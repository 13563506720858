<template>
  <div class="flex justify-center items-center py-6">
    <!-- Hiding Spinner -->
    <!-- <div class="flex flex-col items-center">
      <img
        class="rounded-full animate-spin"
        src="~/assets/images/apple-touch-icon.png"
      />
      <span class="font-bold mt-6"> {{ $t("common.redirect") }} </span>
    </div> -->
  </div>
</template>
